import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root'
})
export class LocalService {
	static hash = LocalService.getStoredHash();

	public static saveData(key: string, value: string) {
		const hash = LocalService.hash;
		if (!!hash) {
			const encryptedData =  LocalService.encrypt(value, hash);
			localStorage.setItem(key, encryptedData);
		}
	}

	public static getData(key: string) {
		let data = localStorage.getItem(key) || "";
		const hash = LocalService.hash!;

		if (!hash || !localStorage.getItem(key)) return "{}";
		return this.decrypt(data, hash);
	}

	public static removeData(key: string) {
		localStorage.removeItem(key);
	}

	public static clearData() {
		localStorage.clear();
	}

	private static encrypt(txt: string, hash: string): string {
		const hashFirstIndex = hash[0];
		const hashLastIndex = hash[hash.length - 1];
		const newHash = hashLastIndex + hash.substring(1, hash.length - 2) + hashFirstIndex;

		return CryptoJS.AES.encrypt(txt, newHash).toString();
	}

	private static decrypt(txtToDecrypt: string, hash: string) {
		const hashFirstIndex = hash[0];
		const hashLastIndex = hash[hash.length - 1];
		const newHash = hashLastIndex + hash.substring(1, hash.length - 2) + hashFirstIndex;

		return CryptoJS.AES.decrypt(txtToDecrypt, newHash).toString(CryptoJS.enc.Utf8);
	}

	private static getStoredHash() {
		if(typeof window !== 'undefined')
			return localStorage.getItem('hash');

		return '';
	}
}
